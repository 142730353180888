import {
    Typography,
    Layout,
    Row,
    Col,
    Affix,
    Image,
    Button,
    Tooltip,
    Popover,
} from 'antd'

import {
    DownloadOutlined,
} from '@ant-design/icons'

import {
    motion,
} from 'framer-motion'

import {
    useContext,
} from 'react'

import { Context } from './store/Context'
import Form from './form';

// props: sectionItem, backgroundColor
function SectionItem(props) {
    return (
        <Row justify='center' align='top' style={{ backgroundColor: props.backgroundColor, padding: '20px' }}>
            <motion.div 
                initial={{ x: -300, opacity: 0 }} 
                whileInView={{ x: 0, opacity: 1, transition: { type: 'spring', bounce: 0, duration: 1 } }} 
                viewport={{ once: true }}
                style={{ width: '100%', maxWidth: '600px', margin: '10px auto' }}
            >
                <Row justify='center'>
                    <Typography.Title level={2}>
                        {props.sectionItem.title}
                    </Typography.Title>
                </Row>
                <Row justify='center' style={{ margin: '10px 0' }}>
                    <Typography style={{ fontSize: '16px' }}>
                        {props.sectionItem.text}
                    </Typography>
                </Row>
                <Row justify='center'>
                    <Image preview={false} src={props.sectionItem.image} style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}></Image>
                </Row>
            </motion.div>
        </Row>
    )
}


// props: sectionList
function SectionList(props) {
    return (
        <>
        {
            props.sectionList.map((sectionItem, index) => {
                return (
                    <SectionItem sectionItem={sectionItem} backgroundColor={index % 2 === 0? 'white': null} />    
                )

            })
        }
        </>
    )
}

function Mobile() {
    const {state, dispatch} = useContext(Context)

    return (
        <Layout style={{'overflow-x': 'hidden'}}>
            <Affix offsetTop={0}>
                <Layout.Header style={{'background': 'white', 'width': '100%'}}>
                    <Row justify='center' align='middle' style={{'backgroundColor': 'white', 'width': '100%', 'height': '100%'}}>
                        <Col>
                            <Image width={30} height={30} preview={false} src={state.appLogo}></Image>
                        </Col>
                        <Col>
                            <Typography.Title level={3} style={{'color': 'black', 'marginLeft': '10px'}}>{state.appName}</Typography.Title>
                        </Col>
                
                    </Row>
                </Layout.Header>
            </Affix>

            <Layout.Content>
                {/* cover headline */}
                <Row justify='center' align='middle' style={{'backgroundColor': 'white', 'height': '800px', 'paddingTop': '50px'}}>
                    <Row justify='center'>
                        <Typography.Title level={2}>
                            {state.coverTitle}
                        </Typography.Title>
                    </Row>

                    <Row justify='start' style={{'padding': '0px 20px'}}>
                        <Typography.Paragraph style={{'fontSize': '16px'}}>
                        {state.coverText1}
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{'fontSize': '16px'}}>
                        {state.coverText2}
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{'fontSize': '16px'}}>
                        {state.coverText3}
                        </Typography.Paragraph>
                    </Row>

                    <Row justify='space-around'>
                        {
                            state.appleStoreLink &&
                            <Col style={{'width': '45%'}}>
                                <Row justify='center'>
                                <a href={state.appleStoreLink} target='_blank' rel="noopener noreferrer">
                                    <Image height={50} preview={false} src={state.appleStoreBadge}></Image>
                                </a>
                                </Row>
                            </Col>
                        }
                        {
                            state.googlePlayLink &&
                            <Col style={{'width': '45%'}}>
                                <Row justify='center'>
                                <a href={state.googlePlayLink} target='_blank' rel="noopener noreferrer">
                                    <Image height={50} style={{'padding': '2px 0'}} preview={false} src={state.googlePlayBadge}></Image>
                                </a>
                                </Row>
                            </Col>
                        }

                    </Row>

                    <Row justify='center'>
                        <Image preview={false} src={state.coverImage} style={{'width': '100%'}}></Image>
                    </Row>
                </Row>

                {/* endorsement list */}
                <Row justify='center' align='top' style={{'height': '500px', 'padding': '70px 20px'}}>                
                    <motion.div
                            initial={{x: -300, opacity: 0}} 
                            whileInView={{x: 0, opacity: 1, transition: {type: 'spring', bounce: 0, duration: 1}}} 
                            viewport={{once: true}}>
                        <Row justify='center'>
                            <Typography.Title level={2}>
                                {state.endorsementTitle}
                            </Typography.Title>
                        </Row>
                        <Row justify='center'>
                            <Typography style={{'fontSize': '16px'}}>
                                {state.endorsementText}
                            </Typography>
                        </Row>
                        <Form/>
                    </motion.div>
                </Row>

                {/* section list */}
                <SectionList sectionList={state.sectionList} />
                

                <Row justify="center" align='middle' style={{'backgroundColor': 'white', 'padding': '0 0 40px 0'}}>
                    <Col>
                        <Typography.Text type="secondary" style={{'fontSize': 12}}>
                            {state.appName} © {new Date().getFullYear()}
                        </Typography.Text>
                    </Col>
                </Row>

            </Layout.Content>

            {/*
            <Layout.Footer>


            </Layout.Footer>
            */}

            </Layout>
    )
}

export default Mobile
